import { BNDLogo, Facebook, LinkedIn, Twitter, Pinterest } from '@jelly/components'
export const HUB_PRESENTED_BY_LOGO = {
	Svg: BNDLogo,
	color: '#000',
	height: '40',
	width: '155',
}

export const SOCIAL_MEDIA_GROUP_ICONS = [
	{ Svg: Facebook, label: 'Visit us on Facebook', slug: 'https://www.facebook.com/BusinessNewsDaily' },
	{ Svg: LinkedIn, label: 'Visit us on LinkedIn', slug: 'https://www.linkedin.com/company/businessnews-daily' },
	{ Svg: Twitter, label: 'Visit us on twitter', slug: 'https://twitter.com/BNDarticles' },
	{ Svg: Pinterest, label: 'Visit us on Pinterest', slug: 'https://www.pinterest.com/businessnewsdaily/' },
]

export {
	BndBlockMonogram as BlockMonogram,
	EmailSignUpMessagingBoxBnd as EmailSignUpMessagingBox,
	FloaterBarContextBnd as FloaterBarContext,
	FooterLogoBnd as FooterLogo,
	HeaderLogoBnd as HeaderLogo,
	BNDLogo as SiteLogo,
	NavigationLinksBnd as NavigationLinks,
} from '@jelly/components'
