import React from 'react'
import { CacheProvider } from '@emotion/core'
import { cache } from 'emotion'
import { ThemeProvider } from 'emotion-theming'
import { ApolloProvider } from './apollo'
import { EmailSubscribeProvider, SiteConfigProvider } from '@jelly/components'
import { bndMuiTheme, bndTheme, MuiTheme } from '@jelly/themes'

import * as constants from './constants'
import * as components from './siteComponents'

/**
 * All pages are wrapped by this component
 */
export default ({ children }) => {
	return (
		<ApolloProvider>
			<MuiTheme theme={bndMuiTheme}>
				<ThemeProvider theme={{ ...bndMuiTheme, ...bndTheme }}>
					<SiteConfigProvider siteConstants={constants} siteComponents={components}>
						<EmailSubscribeProvider>
							<CacheProvider value={cache}>{children}</CacheProvider>
						</EmailSubscribeProvider>
					</SiteConfigProvider>
				</ThemeProvider>
			</MuiTheme>
		</ApolloProvider>
	)
}
