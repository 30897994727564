import React from 'react'
import WrapRootElement from 'common/WrapRootElement'
import { WrapPageElement } from '@jelly/components'
import { hydrate } from 'emotion'
import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
export const onClientEntry = async () => {
	// This JSON script gets written in gatsby-ssr
	const idsTag = document.getElementById('hydrated-emotion-ids')
	if (idsTag) {
		const ids = JSON.parse(idsTag.innerHTML)
		hydrate(ids)
	}
	if (typeof IntersectionObserver === `undefined`) {
		await import(`intersection-observer`)
	}
	if (typeof fetch === `undefined`) {
		await import(`isomorphic-fetch`)
	}
}

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const wrapRootElement = ({ element }) => <WrapRootElement>{element}</WrapRootElement>

/**
 * Wraps every page in a component
 * See: https://www.gatsbyjs.org/docs/ssr-apis/#wrapPageElement
 */
export const wrapPageElement = ({ element, props }) => <WrapPageElement {...props}>{element}</WrapPageElement>
