export const IS_GATSBY_SITE = true
export const SITE_HOST = process.env.GATSBY_SITE_HOST || 'https://www.businessnewsdaily.com'
export const EMAIL_API = process.env.GATSBY_API_EMAIL || 'https://www.business.com/api/email/sign-up/'

export const META_DATA = {
	applicationName: 'Business News Daily',
	canonical: '/',
	description:
		'Get the news you need to succeed in business on Business News Daily. Learn how to start a business, how to grow your business and how to market yourself.',
	ogImage: 'https://www.businessnewsdaily.com/resources/img/ep-bnd-logo-120.png',
	robots: null,
	title: 'Business News Daily: Small Business Solutions & Inspiration',
	twitterName: '@BNDarticles',
}

export const AUTHOR_LINK_ATTRIBUTES = {
	href: `https://www.business.com/advice/member/p/`,
	target: '_blank',
}
export const ARTICLE_SCHEMA_PROPS = {
	name: 'businessnewsdaily.com',
	logoUrl: 'https://www.businessnewsdaily.com/resources/img/ep-bnd-logo-120.png',
	logoWidth: '282',
	logoHeight: '120',
}
export const EMAIL_CAPTURE_SOURCES = {
	article: 'BND_email_articlepage',
	homepage: 'BND_email_home',
	hub: 'BND_email_hubpage',
	subscribeButton: 'BND_email_siteheader',
}

export const HUB_LINKS = [
	{
		fullTitle: 'Start Your Business',
		title: 'Start',
		slug: 'start-your-business',
		subHubs: [
			{ title: 'Business Ideas', slug: 'business-ideas' },
			{ title: 'Business Plans', slug: 'business-plans' },
			{ title: 'Startup Basics', slug: 'startup-basics' },
			{ title: 'Startup Funding', slug: 'startup-funding' },
			{ title: 'Franchising', slug: 'franchising' },
			{ title: 'Success Stories', slug: 'success-stories' },
			{ title: 'Entrepreneurs', slug: 'entrepreneurs' },
		],
		featuredTopics: [
			{ title: '15 Great Small Business Ideas to Start in 2022', path: '/2747-great-business-ideas.html' },
			{
				title: 'The Best Small Business Government Grants in 2022',
				path: '/15758-government-grants-for-small-businesses.html',
			},
			{
				title: 'Startup Costs: How Much Cash Will You Need?',
				path: '/5-small-business-start-up-costs-options.html',
			},
			{
				title: 'How to Get a Bank Loan for Your Small Business',
				path: '/15750-get-business-loan-from-bank.html',
			},
			{ title: 'Best Small Business Loans of 2022', path: '/8448-best-business-loans.html' },
		],
	},
	{
		fullTitle: 'Grow Your Business',
		title: 'Grow',
		slug: 'grow-your-business',
		subHubs: [
			{ title: 'Sales & Marketing', slug: 'sales-marketing' },
			{ title: 'Finances', slug: 'finances' },
			{ title: 'Your Team', slug: 'your-team' },
			{ title: 'Technology', slug: 'technology' },
			{ title: 'Social Media', slug: 'social-media' },
			{ title: 'Security', slug: 'security' },
		],
		featuredTopics: [
			{
				title: 'How to Conduct a Market Analysis for Your Business',
				path: '/15751-conduct-market-analysis.html',
			},
			{
				title: 'Local Marketing Strategies for Success',
				path: '/15770-local-marketing-strategies-for-small-business.html',
			},
			{ title: 'Tips for Hiring a Marketing Company', path: '/15774-tips-for-hiring-a-marketing-company.html' },
			{
				title: 'Benefits of CRM Systems',
				path: '/15963-benefits-of-crm.html',
			},
			{ title: 'The Best Credit Card Processors of 2022', path: '/8061-best-credit-card-processing.html' },
		],
	},
	{
		fullTitle: 'Lead Your Team',
		title: 'Lead',
		slug: 'lead-your-team',
		subHubs: [
			{ title: 'Leadership', slug: 'leadership' },
			{ title: 'Women in Business', slug: 'women-in-business' },
			{ title: 'Managing', slug: 'managing' },
			{ title: 'Strategy', slug: 'strategy' },
			{ title: 'Personal Growth', slug: 'personal-growth' },
		],
		featuredTopics: [
			{
				title: 'Guide to Developing a Training Program for New Employees',
				path: '/15839-new-hire-training.html',
			},
			{ title: 'How Does 401(k) Matching Work for Employers?', path: '/15813-401k-match-for-employers.html' },
			{
				title: 'Why You Need to Create a Fantastic Workplace Culture',
				path: '/15206-company-culture-matters-to-workers.html',
			},
			{
				title: '10 Employee Recruitment Strategies for Success',
				path: '/15821-small-business-recruitment-strategies.html',
			},
			{ title: 'The Best PEOs of 2022', path: '/8117-best-professional-employer-organizations.html' },
		],
	},
	{
		fullTitle: 'Find A Solution',
		title: 'Find',
		slug: 'find-a-solution',
		subHubs: [
			{ title: 'HR Solutions', slug: 'hr-solutions' },
			{ title: 'Financial Solutions', slug: 'financial-solutions' },
			{ title: 'Marketing Solutions', slug: 'marketing-solutions' },
			{ title: 'Security Solutions', slug: 'security-solutions' },
			{ title: 'Retail Solutions', slug: 'retail-solutions' },
			{ title: 'SMB Solutions', slug: 'technology-solutions' },
		],
		featuredTopics: [
			{
				title: 'Best Accounting Software and Invoice Generators of 2022',
				path: '/7543-best-accounting-software.html',
			},
			{ title: 'Best Payroll Services for 2022', path: '/7509-best-payroll-services.html' },
			{ title: 'Best POS Systems for 2022', path: '/2955-best-pos-systems.html' },
			{ title: 'Best CRM Software of 2022', path: '/7839-best-crm-software.html' },
			{
				title: 'Best Call Centers and Answering Services for Businesses for 2022',
				path: '/5473-best-call-center-services.html',
			},
		],
	},
]

export const CONNECT_LINK = 'https://www.business.com/connect/'

export const HAMBURGER_MENU_CORE_LINKS = [
	{
		title: 'Advertise With Us',
		slug: 'https://www.business.com/connect/',
		target: '_blank',
		icon: 'advertise',
		trackingName: 'Hamburger AdvertiseWithUs link',
	},
]

export const SHOULD_SHOW_ADVERTISE_WITH_US_BUTTON = false

export const HUBS_RESULTS_PER_PAGE = 30

export const SEARCH_TAGLINE = 'Your Guide to Business Success'

export const ADVERTORIAL_SINGLE_SPONSOR = ['advertorial', 'single-sponsor']

export const ALL_SPONSOR_TYPES = ['hub-sponsor', ...ADVERTORIAL_SINGLE_SPONSOR]

export const LONG_FORM = 'long_form'

export const RECAPTCHA_SITE_KEY = '6LflxcQUAAAAAA1Idk3BkmOTlDXc88h7mFVySfqU'

let RFQ_PUBLISHER_ID = process.env.GATSBY_RFQ_PUBLISHER_ID
let WIDGET_ENV = process.env.GATSBY_WIDGET_ENV || 'STG'

export { WIDGET_ENV, RFQ_PUBLISHER_ID }

// BND uses the neptune theme
export const RFQ_WIDGET_THEME = 'neptune'

export const DFP_CONTENT_ID_TYPE = 'prefix'
export const DFP_NETWORK_ID = '21735953238'
export const DFP_NOBID_ID = '21863691159'
export const DFP_SITE_NAME = 'bnd'

export const QT_ADMIN_PUB_ID = '1001'
export const HAS_COMMUNITY = false

export const FOOTER_LINKS = {
	resources: [
		{
			dataType: 'link',
			text: 'How to Start a Business',
			link: 'https://www.businessnewsdaily.com/4686-how-to-start-a-business.html',
		},
		{
			dataType: 'link',
			text: 'How to Market Your Business',
			link: 'https://www.businessnewsdaily.com/15769-small-business-marketing-guide.html',
		},
		{
			dataType: 'link',
			text: 'How to Hire For Your Business',
			link: 'https://www.businessnewsdaily.com/15752-employee-hiring-guide.html',
		},
		{
			dataType: 'link',
			text: 'Latest',
			link: '/home/feed/google-news.xml',
		},
	],
	ourCompany: [
		{
			dataType: 'link',
			text: 'About Us',
			link: '/about-us/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Advertise With Us',
			link: 'https://www.business.com/connect/',
			target: '_blank',
		},
		{
			dataType: 'link',
			text: 'Privacy Policy',
			link: '/privacy-policy/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Do Not Sell My Personal Information',
			link: 'https://www.business.com/optout/',
			target: '_blank',
		},
		{
			dataType: 'link',
			text: 'Terms of Use',
			link: '/terms-of-use/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Accessibility',
			link: '/accessibility/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Copyright Policy',
			link: '/copyright-policy/',
			target: '_self',
		},
		{
			dataType: 'link',
			text: 'Advertising Disclosure',
			link: '/advertising-disclosure/',
			target: '_self',
		},
	],

	ourBrands: [
		{
			dataType: 'link',
			text: 'Business.com',
			link: 'https://www.business.com',
			target: '_blank',
			rel: 'nofollow',
		},
		{
			dataType: 'link',
			text: 'BuyerZone',
			link: 'https://www.buyerzone.com',
			target: '_blank',
			rel: 'nofollow',
		},
	],
	contactUs: [
		{
			dataType: 'link',
			text: 'info@businessnewsdaily.com',
			type: 'email',
			link: 'mailto:info@businessnewsdaily.com',
		},
		{
			dataType: 'paragraph',
			type: 'address',
			text: '200 5th Avenue, 2nd Floor',
		},
		{
			dataType: 'paragraph',
			type: 'address',
			text: 'Waltham, MA 02451',
		},
	],
}
