// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-accessibility-mdx": () => import("./../../../src/pages/accessibility.mdx" /* webpackChunkName: "component---src-pages-accessibility-mdx" */),
  "component---src-pages-advertising-disclosure-mdx": () => import("./../../../src/pages/advertising-disclosure.mdx" /* webpackChunkName: "component---src-pages-advertising-disclosure-mdx" */),
  "component---src-pages-copyright-policy-mdx": () => import("./../../../src/pages/copyright-policy.mdx" /* webpackChunkName: "component---src-pages-copyright-policy-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-preview-index-jsx": () => import("./../../../src/pages/preview/index.jsx" /* webpackChunkName: "component---src-pages-preview-index-jsx" */),
  "component---src-pages-privacy-policy-index-mdx": () => import("./../../../src/pages/privacy-policy/index.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-index-mdx" */),
  "component---src-pages-sitemap-best-picks-jsx": () => import("./../../../src/pages/sitemap/best-picks.jsx" /* webpackChunkName: "component---src-pages-sitemap-best-picks-jsx" */),
  "component---src-pages-sitemap-index-jsx": () => import("./../../../src/pages/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-sitemap-index-jsx" */),
  "component---src-pages-terms-of-use-mdx": () => import("./../../../src/pages/terms-of-use.mdx" /* webpackChunkName: "component---src-pages-terms-of-use-mdx" */),
  "component---src-templates-articles-index-jsx": () => import("./../../../src/templates/articles/index.jsx" /* webpackChunkName: "component---src-templates-articles-index-jsx" */),
  "component---src-templates-articles-link-building-jsx": () => import("./../../../src/templates/articles/linkBuilding.jsx" /* webpackChunkName: "component---src-templates-articles-link-building-jsx" */),
  "component---src-templates-categories-mega-index-jsx": () => import("./../../../src/templates/categories/mega/index.jsx" /* webpackChunkName: "component---src-templates-categories-mega-index-jsx" */),
  "component---src-templates-hubs-index-jsx": () => import("./../../../src/templates/hubs/index.jsx" /* webpackChunkName: "component---src-templates-hubs-index-jsx" */),
  "component---src-templates-reviews-index-jsx": () => import("./../../../src/templates/reviews/index.jsx" /* webpackChunkName: "component---src-templates-reviews-index-jsx" */),
  "component---src-templates-sitemap-index-jsx": () => import("./../../../src/templates/sitemap/index.jsx" /* webpackChunkName: "component---src-templates-sitemap-index-jsx" */)
}

